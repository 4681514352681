
.rw-number-picker {
  $half-width: $input-height / 2;

  .rw-btn {
    @include make-btn-state();
    @include height-calc($half-width, $input-border-width);

    line-height: $half-width;
    line-height: calc(#{$half-width} - #{$input-border-width});
    display: block;
    border: none;
  }

  & .rw-select {
    vertical-align: middle;
    
    &:hover,
    &:active {
      box-shadow: none;
    }
  }
}
