.rbc-time-header {
  overflow: hidden;
}
.app-header__logo .logo-src {
  height: 60px;
}
.app-header__logo {
  width: 250px;
}

@media (min-width: 500px) {
  .hamburger {
    display: none;
  }
  .app-sidebar {
    display: none;
  }
  .closed-sidebar.fixed-sidebar .app-main__outer {
    padding: 50px;
  }
  .fixed-sidebar .app-main .app-main__outer {
    padding: 25px;
  }
}
.app-page-title {
  padding: 0px;
  /* margin-bottom: 10px; */
}
.f10 {
  font-size: 10px !important;
}
.app-page-title .app-page-title-simple {
  padding-bottom: 0;
}
.app-main .app-main__inner {
  padding-top: 20px;
}
